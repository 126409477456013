
const aWindow = (window as any);
let voices: any = [];

const SpeechRecognition =
    aWindow.SpeechRecognition || aWindow.webkitSpeechRecognition;

const SpeechGrammarList =
    aWindow.SpeechGrammarList || aWindow.webkitSpeechGrammarList;
(() => {
    try {
        aWindow.speechSynthesis.onvoiceschanged = () => {
            const vs = aWindow.speechSynthesis.getVoices();
            const topVoices = [];
            const allowVoices = [];

            for (const voice of vs) {
                if (['en-US', 'en-GB'].indexOf(voice.lang) >= 0) {
                    if (voice.name.indexOf('Google') >= 0) {
                        topVoices.push(voice);
                    } else {
                        allowVoices.push(voice);
                    }
                }
            }

            voices = [...topVoices.reverse(), ...allowVoices].slice(0, 5);
        }
    } catch (ingored) {
    }
})();

export function getVoices(setter: Function) {
    if (voices.length > 0) {
        setter(voices);
        return;
    }

    setTimeout(() => getVoices(setter), 1000);
}

export function speak(text: string, voice: any, onEnd: Function) {
    const msg = new aWindow.SpeechSynthesisUtterance(text);
    msg.lang = "en-GB";
    msg.voice = voice;
    msg.addEventListener("end", onEnd);
    window.speechSynthesis.speak(msg);
}

export function createReconizer(phrase: string, continuous?: boolean) {
    const grammar = `#JSGF V1.0; grammar phrase; public <phrase> = ${phrase};`;
    const recognition = new SpeechRecognition();
    const speechRecognitionList = new SpeechGrammarList();
    speechRecognitionList.addFromString(grammar, 1);
    recognition.grammars = speechRecognitionList;
    recognition.lang = 'en-US';
    recognition.interimResults = false;
    recognition.continuous = continuous || false;
    recognition.maxAlternatives = 1;
    return recognition;
}
