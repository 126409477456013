
import React, { FC } from 'react';
import History from './History';
import ChatBox from './ChatBox';
import PayModal from 'components/PayModal';
import { GetWallet } from 'services/SentenceService';
import Navbar from './Navbar';
import { addLayoutHook, getLayoutState } from './LayoutService';


interface Props { }

const Layout: FC<Props> = () => {
    const [paying, setPaying] = React.useState(false);
    const [wallet, setWallet] = React.useState<any>(null);
    const [layout, setLayout] = React.useState<any>(getLayoutState());

    async function fetchWallet() {
        const w = await GetWallet();
        setWallet(w);
        if (w && w.total < 1) {
            setPaying(true);
        }
    }

    React.useEffect(() => {
        fetchWallet();
        return addLayoutHook(setLayout);
    }, []);

    function handleOnPaid() {
        setPaying(false);
        fetchWallet();
    }

    return (
        <>
            <div style={{ 
                display: 'flex', 
                flexDirection: "column", 
                height: "100vh", ...(window.innerWidth < 450 ? {} : {overflowY: "hidden"}) 
            }}>
                <Navbar title="Học tiếng Anh" />
                {/* <style>
                    {`#root, html, body { height: 100vh; overflow-y: hidden; }`}
                </style> */}
                <style>
                    {`
                    #fb-root, #fb-customer-chat { display: none; }
                    body {
                        padding-bottom: 0px!important;
                    }
                `}
                </style>
                <div style={{
                    width: "100vw",
                    flex: 1,
                    paddingTop: "4px",
                    display: 'flex',
                    background: "#f0f0f0",
                    overflowX: "hidden",
                }}>
                    {!layout.isSmall && <div
                        style={{ flex: 1, background: "#f0f0f0", zIndex: 100 }}
                    />}
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        maxWidth: '1400px',
                        border: '1px solid #d3e2fd',
                        // borderRight: '1px solid #d3e2fd',
                        borderRadius: '8px',
                        height: `${
                          (window.innerWidth < 450 ? window.outerHeight :  window.innerHeight) - 62
                        }px`,
                        overflow: 'hidden',
                    }}>
                        <History
                            layout={layout}
                            wallet={wallet}
                            onPay={() => setPaying(true)}
                        />
                        <ChatBox
                            layout={layout}
                            onCharged={() => fetchWallet()}
                            onPay={() => setPaying(true)}
                        />
                    </div>
                    {!layout.isSmall && <div
                        style={{ flex: 1, background: "#f0f0f0", zIndex: 100 }}
                    />}
                </div>
            </div>
            {paying && <PayModal
                open={paying}
                item={{ key: "M4_20000", price: 1499000, name: "20.000 M4 (20.000 Từ)" }}
                onPaid={handleOnPaid}
                onClose={() => { setPaying(false); }}
            />}
        </>
    );
}

export default Layout;
