
import React, { FC } from 'react';

import s from './Thread.module.scss';

import * as types from './types';
import { useNavigate, useParams } from 'react-router-dom';
import { checkLayout } from '../LayoutService';
import { getMessages } from 'services/ThreadService';

interface Props {
    thread: types.Thread;
}

let it: any = -1;

function timeSince(date: number) {

    let seconds = Math.floor((Date.now() - date) / 1000);
    if (seconds < 1) {
        seconds = 1;
    }

    let interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " năm";
    }

    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " tháng";
    }

    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " ngày";
    }

    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " giờ";
    }

    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " phút";
    }

    return Math.floor(seconds) + " giây";
}

const Thread: FC<Props> = ({ thread }) => {
    const { thread: threadId } = useParams();
    const navigate = useNavigate();
    const isActive = threadId === thread.id;
    const [lastMessage, setLastMessage] = React.useState<types.Message | null>(null);

    React.useEffect(() => {
        const fetchMessages = async () => {
            const msgs: any[] = await getMessages(thread.id);
            if (msgs.length === 0) {
                setLastMessage(null);
                return;
            }

            const lm = msgs[msgs.length - 1];
            setLastMessage(lm);
        }

        fetchMessages();
        if (it !== -1) {
            clearInterval(it);
        }
        it = setInterval(fetchMessages, 5000);
        return () => {
            const cit = it;
            it = -1;
            if (cit !== -1) {
                clearInterval(cit)
            }
        };
    }, [threadId]);

    return (
        <div
            className={isActive ? [s.Thread, s.Active].join(' ') : s.Thread}
            onClick={() => {
                if (!isActive) {
                    navigate(`/chat/${thread.id}`);
                }
                checkLayout();
            }}
        >
            <div className={s.Avatar} />
            <div className={s.Content}>
                <div className={s.Title}>
                    <div className={s.Main}>
                        {thread.title}
                    </div>
                    <div className={s.Sub}>
                        {timeSince(lastMessage?.updatedAt || thread.updatedAt)} trước
                    </div>
                </div>
                <div className={s.LastMessage}>
                    {lastMessage?.content || 'Xin chào! Tôi là Matt. Hãy cùng tôi nâng cấp trình độ tiếng Anh nhé!'}
                </div>
                <div className={s.Tags}>
                    {/* <div className={s.Tag}>
                        {thread.ws} từ
                    </div> */}
                    {/* <div className={s.Tag}>
                        {thread.m4} m4
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Thread;
