import React from "react";
import s from './WavingButton.module.scss';

export default function WavingButton({ waving, children } : { waving?: boolean, children: React.ReactElement }) {
    return waving ? (<div className={s.wavingButton}>
        <div className={s.box}>
            {children}
        </div>
        <div className={`${s.circle} ${s.w1}`}></div>
        <div className={`${s.circle} ${s.w2}`}></div>
        <div className={`${s.circle} ${s.w3}`}></div>
        <div className={`${s.circle} ${s.w4}`}></div>
    </div>) : children;
}
