
import React from 'react';
import { Modal, Button, Select, QRCode } from 'antd';

import s from './PayModal.module.scss';
import { Buy, PayConfirm } from 'services/SentenceService';
import { copyText, showError, showInfo, showSuccess } from 'services/Noti';

const qOptions = [{
    value: 1,
    label: "1"
}, {
    value: 3,
    label: "3"
}, {
    value: 5,
    label: "5"
}, {
    value: 10,
    label: "10"
}, {
    value: 25,
    label: "25"
}, {
    value: 50,
    label: "50"
}];

interface Payment {
    description: string;
    amount: number;
    accountNumber: string;
    accountName: string;
    orderCode: number;
    qrCode: string;
}

interface Item {
    key: string;
    name: string;
    price: number;
}

interface CartProps {
    item: Item;
    onClose: Function;
    onPay: Function;
}

interface QrModalProps {
    payment: Payment;
    onClose: Function;
    onPaid: Function
}

function getDiscount(quantity: number) {
    if (quantity >= 10) {
        return 35;
    }

    if (quantity >= 5) {
        return 25;
    }

    if (quantity >= 3) {
        return 10;
    }

    return 0;
}

function getPrice(price: number, quantity: number) {
    const discount = getDiscount(quantity);
    if (discount) {
        return Math.round(price * (1 - discount / 100) * quantity);
    }
    return price * quantity;
}

const Cart: React.FC<CartProps> = ({ item, onClose, onPay }) => {
    const [buying, setBuyIng] = React.useState(false);
    const [quantity, setQuantity] = React.useState(qOptions[0]);

    const handleBack = () => {
        if (buying) return;
        onClose();
    }

    const handleBuy = () => {
        if (buying) return;
        setBuyIng(true);
        setTimeout(async () => {
            const payment = await Buy(item.key, quantity.value);
            setBuyIng(false);
            if (payment.checkoutUrl) {
                onPay(payment);
            }
        }, 1000);
    }

    const discount = getDiscount(quantity.value);
    const price = getPrice(item.price, quantity.value);

    return (
        <Modal
            title="Kiểm tra thông tin"
            open
            onOk={() => { }}
            onCancel={() => onClose()}
            footer={[
                <Button type="link" key="back" onClick={handleBack}>
                    Quay lại
                </Button>,
                <Button
                    loading={buying}
                    type="primary"
                    key="buy"
                    onClick={handleBuy}
                >
                    Mua ngay
                </Button>,
            ]}
        >
            <div className={s.Cart}>
                <div className={s.Item}>
                    <div className={[s.Name, s.Row].join(' ')}>
                        <div className={s.Flex}>
                            <div className={s.Left}>
                                <label>Tên:</label>
                                <b>
                                    {item.name}
                                </b>
                            </div>
                            <div>
                                <label>Số lượng:</label>
                                <Select
                                    style={{ fontWeight: 'bold', width: "60px" }}
                                    value={quantity.value}
                                    onChange={(q) => {
                                        const qo = qOptions.find((o) => o.value === q);
                                        if (qo) {
                                            setQuantity(qo);
                                        }
                                    }}
                                    options={qOptions}
                                    size="small"
                                />
                            </div>
                        </div>
                    </div>
                    <div className={[s.Price, s.Row].join(' ')}>
                        <div className={s.Flex}>
                            <div className={s.Left}>
                                <label>Đơn giá:</label>
                                <b>
                                    {formatAmount(2000000)}đ
                                </b>
                            </div>
                            <div>
                                <label>Giảm giá:</label>
                                <b>35%</b> (còn <strong>{formatAmount(item.price)}đ</strong>)
                            </div>
                        </div>
                    </div>
                    <div className={[s.Total, s.Row].join(' ')}>
                        <div className={s.Flex}>
                            <div className={s.Left}>
                                <label>Hạn sử dụng:</label>
                                <b>Một năm kể từ ngày thanh toán</b>
                            </div>
                            <div>
                                <label>Giảm theo số lượng:</label>
                                <b>{discount}%</b>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label>Tổng cộng:</label>
                        <b>{formatAmount(price)} đ</b>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

function mask(str: string) {
    return str.replace(/\b(\w+)\b(?=\s|$)/g, (_, p1, offset) => {
        if (str.indexOf(' ', offset) === -1) return p1;
        return '*'.repeat(p1.length);
    });
}

function formatAmount(amount: number) {
    return amount.toLocaleString('vi-VN');
}

const QrModal: React.FC<QrModalProps> = ({ payment: {
    description, amount, accountNumber, accountName,
    orderCode, qrCode,
}, onClose, onPaid }) => {

    const [checking, checkStatus] = React.useState(false);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            showInfo('Đã quá giới hạn thời gian cho phép. Bạn cần thực hiện lại giao dịch.');
            onClose();
        }, 25 * 60 * 1000);
        return () => clearTimeout(timer);
    }, []);


    const handleCopy = (txt: string) => {
        copyText(txt);
    }

    const qrSize = 260;

    return (
        <Modal
            title="Thanh toán"
            open
            centered
            onOk={() => { }}
            onCancel={() => onClose()}
            footer={[
                <Button
                    type="link" key="back"
                    onClick={() => {
                        if (checking) return;
                        onClose();
                    }}>
                    Quay lại
                </Button>,
                <Button
                    type="primary"
                    key="buy"
                    loading={checking}
                    onClick={() => {
                        if (checking) return;
                        checkStatus(true);

                        const c = async () => {
                            const { status } = await PayConfirm(orderCode);
                            if (!status) {
                                return;
                            }
                            const sts = status.toUpperCase();
                            if (sts === 'PAID') {
                                showSuccess('Bạn đã thanh toán thành công. Xin cảm ơn vì đã đồng hành cùng MJ4!');
                                onPaid();
                                return;
                            }

                            if (sts === 'CANCELLED' || sts === 'EXPIRED') {
                                showError('Giao dịch đã bị huỷ hoặc đã quá thời gian thanh toán. Bạn có thể thử thanh toán lại.')
                                onClose();
                                return;
                            }

                            showInfo('Giao dịch chưa hoàn tất. Nếu đã thanh toán bạn hãy chờ trong ít phút rồi nhấn vào nút "Đã thanh toán" để kiểm tra lại nhé.')
                            checkStatus(false);
                        }
                        setTimeout(c, 1000);
                    }}
                >
                    Đã thanh toán
                </Button>,
            ]}
        >
            <div className={s.Payment}>
                <div className={s.Help}>
                    <img src='/lamp.svg' alt='' />
                    <div className={s.Text}>
                        Mở App Ngân hàng bất kỳ hoặc MoMo để <b>quét mã VietQR</b> hoặc <b>chuyển khoản</b> chính xác số tiền bên dưới
                    </div>
                </div>
                <div className={s.QrCode}>
                    <QRCode
                        style={{
                            border: "1px solid #6655ff", padding: "1px",
                            background: "#fff",
                            margin: "8px"
                        }}
                        bordered={false}
                        errorLevel="M"
                        size={qrSize}
                        value={qrCode}
                        icon="/common/logo.png"
                    />
                </div>
                <div className={s.Bank}>
                    <img src="/common/mbbank.png" />
                    <div>
                        <div className={s.Label}>Ngân hàng</div>
                        <div className={s.Name}>Ngân hàng TMCP Quân đội</div>
                    </div>
                </div>
                <div className={s.Account}>
                    <div>
                        <div className={s.Label}>Chủ tài khoản:</div>
                        <div className={s.Name}>{mask(accountName)}</div>
                    </div>
                </div>
                <div className={s.Account}>
                    <div>
                        <div className={s.Label}>Số tài khoản:</div>
                        <div className={s.Name}>{accountNumber}</div>
                    </div>
                    <div
                        className={s.Copy}
                        onClick={() => handleCopy(accountNumber)}
                    >
                        Sao chép
                    </div>
                </div>
                <div className={s.Account}>
                    <div>
                        <div className={s.Label}>Số tiền:</div>
                        <div className={s.Name}>{formatAmount(amount)} đ</div>
                    </div>
                    <div
                        className={s.Copy}
                        onClick={() => handleCopy(`${amount}`)}
                    >
                        Sao chép
                    </div>
                </div>
                <div className={s.Account}>
                    <div>
                        <div className={s.Label}>Nội dung:</div>
                        <div className={s.Name}>{description.replace(/\s+/g, ' ')}</div>
                    </div>
                    <div
                        className={s.Copy}
                        onClick={() => handleCopy(description)}
                    >
                        Sao chép
                    </div>
                </div>
                <div className={s.Note}>
                    Lưu ý : Nhập chính xác số tiền <strong>{formatAmount(amount)}</strong>đ khi chuyển khoản
                </div>
            </div>
        </Modal>
    )
}

interface PayModalProps {
    item: Item;
    open: boolean;
    onClose: Function;
    onPaid: Function
}

const PayModal: React.FC<PayModalProps> = ({ item, open, onClose, onPaid }) => {
    const [payment, setPayment] = React.useState<Payment | undefined>(undefined);

    if (!open) {
        return null;
    }

    return payment ? (
        <QrModal
            payment={payment}
            onClose={onClose}
            onPaid={onPaid}
        />
    ) : (
        <Cart item={item} onPay={(p: any) => setPayment(p)} onClose={onClose} />
    );
}

export default PayModal;
