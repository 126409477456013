const globalHooks:Function[] = [];
const small = 1024;
const large = 1100;

export function getLayoutState() {
    const st = localStorage.getItem('ls-mode');
    if (st) {
        return JSON.parse(st);
    }

    const cur = {
        left: true,
        main: true,
        right: true,
        isSmall: false,
    };

    const size = window.innerWidth;

    if (size < small) {
        cur.right = false;
        cur.left = false;
        cur.isSmall = true;
    } else if (size < large) {
        cur.right = false;
    }

    return cur;
}

function saveLayoutState(state: any) {
    localStorage.setItem('ls-mode', JSON.stringify(state));
}

function mergeLayoutState(state: any) {
    const cur = getLayoutState();
    const size = window.innerWidth;
    cur.isSmall = size < small;
    if (state.left !== undefined) {
        cur.left = state.left;
        if (state.left) {
            if (size < small) {
                cur.main = false;
                cur.right = false;
            } else if (size < large) {
                cur.right = false;
            }
            return cur;
        }
        if (size < small) {
            cur.main = true;
            cur.right = false;
        } else if (size < large) {
            cur.main = true;
            cur.right = true;
        }
        return cur;
    }

    if (state.right !== undefined) {
        cur.right = state.right;
        if (state.right) {
            if (size < small) {
                cur.main = false;
                cur.left = false;
            } else if (size < large) {
                cur.left = false;
            }
            return cur;
        }
        if (size < small) {
            cur.main = true;
            cur.left = false;
        } else if (size < large) {
            cur.main = true;
            cur.left = true;
        }
        return cur;
    }

    return cur;
}

export function setLayoutState(state: any) {
    const newState = mergeLayoutState(state);
    saveLayoutState(newState);

    for (const hook of globalHooks) {
        try {
            hook(newState);
        } catch (ignored) {
        }
    }
}

export function addLayoutHook(hook: Function) {
    globalHooks.push(hook);
    return () => {
        const index = globalHooks.indexOf(hook);
        if (index >= 0) {
            globalHooks.splice(index, 1);
        }
    }
}

export function checkLayout() {
    const cur = getLayoutState();
    if (cur.isSmall) {
        if (!cur.main) {
            setLayoutState({ main: true, left: false, right: false });
        }
        return;
    }
}
