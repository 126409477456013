import React from 'react';

import s from './NotePad.module.scss';
import * as types from './types';
import { Button, Flex, Input, Tabs, TabsProps } from 'antd';
import { CopyOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { copyText } from 'services/Noti';
import { checkLayout, setLayoutState } from '../LayoutService';

const { TextArea } = Input;

interface Props {
    layout: any;
    onSendMessage: (msg: types.Message) => void;
};

const tabs: TabsProps['items'] = [
    {
        key: '0',
        label: 'Nguyên văn',
        children: null,
    },
    {
        key: '1',
        label: 'Nhập ý chính',
        children: null,
    },
    {
        key: '2',
        label: 'Dùng tiếng Việt',
        children: null,
    }
];

function getMode() {
    const mode = localStorage.getItem('nce-mode');
    return mode || "0";
}

function saveMode(mode: string) {
    localStorage.setItem('nce-mode', mode);
}

const getAction = (mode: string) => {
    if (mode === "0") {
      return 101;
    }
  
    if (mode === "1") {
      return 100;
    }
  
    return 102;
  }

const NotePad: React.FC<Props> = ({ layout, onSendMessage }) => {
    const [value, setValue] = React.useState<string>('');
    const [tab, setTab] = React.useState<string>(getMode());
    const [stats, setStats] = React.useState<any>({
        cost: 0,
        input: 0,
        output: 0,
    });

    const handleSendMessage = () => {
        let v = value.trim();
        if (v === '') {
            return;
        }

        const st = stats;
        setValue('');
        setStats({input: 0, output: 0, cost: 0});
    
        const msg = {
            id: "-",
            content: v,
            action: getAction(tab),
            createdAt: new Date().getTime(),
            updatedAt: new Date().getTime(),
            sender: {
              id: "-",
              name: "",
              role: 1,
            }
          };
      
          try {
            onSendMessage(msg);
            checkLayout();
          } catch (error) {
            setValue(v);
            setStats(st);
          }
    }

    if (!layout.right) {
        return (<div style={{ background: "#fff", boxShadow: "3px 0px 14px 1px rgba(31, 73, 125, 0.8)" }}>
            <Button
                type="text"
                size="small"
                onClick={() => setLayoutState({ right: true })}
                title="Mở rộng"
            >
                <MenuFoldOutlined />
            </Button>
        </div>);
    }

    return (
        <Flex vertical flex={layout.isSmall ? 1 : 0.4} style={{ boxShadow: "3px 0px 14px 1px rgba(31, 73, 125, 0.8)" }}>
            <div className={s.Header}>
                <div className={s.Title}>Trình soạn thảo</div>
                <div>
                    <Button
                        title='Sao chép nội dung'
                        type="text"
                        size="small"
                        disabled={value.trim() === ''}
                        onClick={() => {
                            copyText(value.trim());
                        }}
                    >
                        <CopyOutlined />
                    </Button>
                    <Button
                        size="small"
                        type="text"
                        onClick={() => setLayoutState({ right: false })}
                        title={'Thu nhỏ'}
                    >
                        <MenuUnfoldOutlined />
                    </Button>
                </div>
            </div>
            <Tabs
                className={s.Tabs}
                activeKey={tab}
                items={tabs}
                onChange={(tab) => {
                    setTab(tab);
                    saveMode(tab);
                }}
            />
            <TextArea
                maxLength={1000}
                value={value}
                className={s.Input}
                onChange={(e) => {
                    const v = e.target.value;
                    setValue(v);
                    const words = v.split(/[\s;\-.!,_/+#%*()]+/).filter((w) => !!w.trim());
                    const input = words.length;
                    const output = input > 0 ? (input < 15 ? 15 : input) * 3 : 0;
                    const cost = input + output;
                    setStats({ cost, input, output });
                }}

            // placeholder="Nhập vào nội dung văn bạn muốn học"
            />
            <div style={{ position: 'relative', width: '100%' }}>
            <Button 
                type='primary'
                style={{ position: 'absolute', right: "10px", top: "-40px" }}
                onClick={handleSendMessage}
                disabled={value.trim() === ''}
             >Gửi</Button>
            </div>
            <div className={s.ToolBox}>
                <Flex>
                    <div style={{ flex: 1}}>
                {/* <div className={s.Title}>Thống kê</div> */}
                <div className={s.Line}>Nội dung nhập <b>{stats.input}</b> từ</div>
                {/* <div className={s.Line}>Phản hồi dự kiến <b>{stats.output}</b> từ</div> */}
                <div className={s.Line}>Lượng M4 dự kiến <b>{stats.cost}</b></div>
                </div>
                </Flex>
            </div>
        </Flex>
    )
};

export default NotePad;
