
import s from './Contact.module.scss';

import React from 'react';
import { Alert, Button, Form, Input } from 'antd';
import Navbar from 'components/Chat/Navbar';
import { Register } from 'services/SentenceService';

const validateMessages = {
    required: '${label} không được để trống!',
    types: {
        email: '${label} không hợp lệ!',
    }
};

const Contact: React.FC = () => {
    const [form] = Form.useForm();
    const [mode, setMode] = React.useState<number>(0);
    const [sending, setSending] = React.useState<boolean>(false);

    return (
        <div className={s.Container}>
            <Navbar title='Liên hệ' />
            <div className={s.Page}>
                <div className={s.Info}>
                    {mode === 0 && (<Alert
                        message="Hãy để lại thông tin, chúng tôi sẽ liên hệ với bạn ngay."
                        type="info"
                        showIcon
                    />)}
                    {mode === 1 && (<Alert
                        message="Thông tin đã được tiếp nhận, chúng tôi sẽ liên hệ với bạn ngay."
                        type="success"
                        showIcon
                    />)}
                    {mode === -1 && (<Alert
                        message="Có lỗi xảy ra, vui lòng thử lại sau."
                        type="error"
                        showIcon
                    />)}
                </div>
                <div className={s.Form}>
                    <Form
                        form={form}
                        onFinish={async (values) => {
                            if (sending) {
                                return;
                            }
                            setSending(true);
                            try {
                                await Register(values.user);
                                setMode(1);
                            } catch (ignored) {
                                setMode(-1);
                                setSending(false);
                            }
                        }}
                        layout="vertical"
                        name="contact_form"
                        style={{ maxWidth: 600 }}
                        autoComplete="off"
                        initialValues={{ items: [{}] }}
                        validateMessages={validateMessages}
                    >
                        <Form.Item name={['user', 'name']} label="Họ tên" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={['user', 'email']} label="Email" rules={[{ type: 'email' }, { required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={['user', 'phone']} label="Số điện thoại" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name={['user', 'message']} label="Nội dung " rules={[{ required: true }]}>
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item>
                            <div className={s.Tools}>
                                <div className={s.Filled}></div>
                                <Button 
                                    type="primary" 
                                    htmlType="submit"
                                    loading={mode === 0 && sending}
                                >
                                    Xác nhận
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
