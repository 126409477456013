import { Typography } from 'antd';
import Navbar from 'components/Chat/Navbar';
const { Title, Text } = Typography;


export default function Policy() {
    return (<>
        <Navbar title="Điều khoản sử dụng" />
        <div style={{ margin: 16 }}>
            <Text>
                Bằng cách truy cập và sử dụng <b>english.major4.io</b>, bạn đồng ý tuân thủ và tuân thế các điều khoản và điều kiện sau đây. Nếu bạn không đồng ý với bất kỳ điều khoản nào, bạn không được phép sử dụng trang web này.
            </Text>
            <Title level={3}>I. Bản quyền:</Title>
            <Text>
                Tất cả nội dung, tài liệu, hình ảnh, video và âm thanh trên trang web <b>english.major4.io</b> đều thuộc quyền sở hữu của chúng tôi. Bạn không được sao chép, phân phối, tái bản, tải lên, đăng tải hoặc sử dụng bất kỳ nội dung nào mà không có sự cho phép trước bằng văn bản từ chúng tôi.
            </Text>

            <Title level={3}>II. Bảo mật thông tin:</Title>
            <Text>Chúng tôi cam kết bảo vệ thông tin cá nhân của bạn theo Chính sách Bảo mật của chúng tôi. Tuy nhiên, chúng tôi không chịu trách nhiệm đối với bất kỳ mất mát hoặc rò rỉ thông tin cá nhân nào do sự cố nằm ngoài tầm kiểm soát của chúng tôi.</Text>

            <Title level={3}>III. Nội dung người dùng:</Title>
            <Text><b>english.major4.io</b> có thể cho phép người dùng đăng tải nội dung của riêng họ, như bình luận hoặc đánh giá. Bằng cách đăng tải nội dung, bạn đồng ý rằng bạn có quyền sở hữu hoặc quyền sử dụng nội dung đó và không vi phạm bất kỳ quyền sở hữu trí tuệ hoặc quyền riêng tư nào của bên thứ ba. Chúng tôi có quyền xóa bất kỳ nội dung người dùng nào mà chúng tôi cho là vi phạm các quyền này.</Text>

            <Title level={3}>IV. Liên kết đến bên thứ ba:</Title>
            <Text>Trang web <b>english.major4.io</b> có thể chứa liên kết đến các trang web của bên thứ ba. Chúng tôi không chịu trách nhiệm về nội dung hoặc hoạt động của bất kỳ trang web nào không thuộc sở hữu hoặc kiểm soát của chúng tôi. Việc truy cập vào các trang web bên thứ ba là do bạn tự chịu rủi ro và tuân thủ các điều khoản và điều kiện của trang web đó.</Text>

            <Title level={3}>V. Thay đổi điều khoản:</Title>
            <Text>Chúng tôi có quyền thay đổi, sửa đổi hoặc cập nhật bất kỳ điều khoản và điều kiện nào trong tài liệu này mà không cần thông báo trước. Việc tiếp tục sử dụng trang web sau khi thay đổi có nghĩa là bạn chấp nhận và đồng ý tuân thủ các điều khoản và điều kiện mới.</Text>

            <Title level={3}>VI. Giới hạn trách nhiệm:</Title>
            <Text>Chúng tôi không chịu trách nhiệm đối với bất kỳ thiệt hại trực tiếp, ngẫu nhiên, hoặc gián tiếp nào phát sinh từ việc sử dụng trang web <b>english.major4.io</b> hoặc việc không thể truy cập vào trang web này.</Text>

            <Title level={3}>VII. Luật áp dụng:</Title>
            <Text>Các điều khoản và điều kiện này sẽ được điều chỉnh và hiểu theo luật pháp của quốc gia nơi trang web <b>english.major4.io</b> được vận hành.</Text> 
        </div>
    </>);
}
