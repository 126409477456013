import { InitWs } from "services/SentenceService";

const hooks : Function [] = [];

export function wsHook(hook : Function) {
    hooks.push(hook);
    return () => {
        const index = hooks.indexOf(hook);
        if (index >= 0) {
            hooks.splice(index, 1);
        }
    };
}

function trigger(data: any, error: any) {
    for (const hook of hooks) {
        try {
            hook(data, error);
        } catch (ignored) {
        }
    }
}

export async function wsSend(msg:any) {
    function resend(evt: any) {
        if (msg.$wsSent || msg.$wsRetrying) {
            return;
        }

        msg.$wsTries = (msg.$wsTries || 0) + 1;
        if (msg.$wsTries > 10) {
            trigger(msg, evt);
            return;
        }

        msg.$wsRetrying = true;
        console.log("retrying", msg.$wsTries);
        setTimeout(() => {
            wsSend(msg);
        }, 500);
    }

    try {
        msg.$wsRetrying = false;

        const { token } = await InitWs();
        const wsHost = process.env.REACT_APP_WS_URL || "wss://english.major4.io";
        const s = new WebSocket(`${wsHost}/v1/ai/chat/${token}`);

        s.addEventListener("open", () => {
            s.send(JSON.stringify(msg));
        });

        s.addEventListener("message", (evt) => {
            msg.$wsSent = true;
            const data = JSON.parse(evt.data);
            trigger(data, null);
        });

        s.addEventListener("error", (evt) => {
            console.log("sendError", evt);
            // retry if error
            resend(evt);
        })

        s.addEventListener("close", (evt) => {
            // try resend if not sent
            resend(evt);
        });
    } catch (err) {
        trigger(msg, err);
    }
}
