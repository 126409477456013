import React, { FC } from 'react';

import * as types from './types';
import s from './ChatInput.module.scss'
import { Segmented } from 'antd';

interface Props {
  onSendMessage: (msg: types.Message) => void;
}

const getAction = (mode: number) => {
  if (mode === 0) {
    return 101;
  }

  if (mode === 1) {
    return 100;
  }

  return 102;
}

function getHelp(mode: number) {
  if (mode === 0) {
    return "Nhập nguyên văn bằng tiếng Anh. Mình sẽ bám sát nội dung để trò chuyện với bạn";
  }

  if (mode === 1) {
    return "Nhập ý chính bằng tiếng Anh. Mình sẽ giúp bạn chuyển nó thành nội dung hoàn chỉnh.";
  }

  return "Bạn nghĩ gì thì viết đó, mình sẽ giúp bạn chuyển sang tiếng Anh.";
}

function getMode() {
  const mode = localStorage.getItem('chat-mode');
  if (mode) {
    return parseInt(mode);
  }
  return 0;
}

function saveMode(mode: number) {
  localStorage.setItem('chat-mode', mode.toString());
}

const ChatInput: FC<Props> = ({ onSendMessage }) => {
  const [data, setData] = React.useState<{ content: string, mode: number, submitting: boolean }>({ content: '', mode: getMode(), submitting: false });
  const modes = ['Nguyên văn', 'Ý chính', 'Tiếng Việt'];
  function handleSendMessage() {
    if (data.submitting) {
      return;
    }
    const txt = data.content.trim();
    setData({ ...data, submitting: true });
    if (txt === "" || txt.length > 1000) {
      setData({ ...data, submitting: false });
      return;
    }

    const msg = {
      id: "-",
      content: txt,
      action: getAction(data.mode),
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      sender: {
        id: "-",
        name: "",
        role: 1,
      }
    };

    try {
      onSendMessage(msg);
    } catch (error) {
      console.log(error);
    }
    setData({ ...data, content: '', submitting: false });
  }

  return (
    <div className={s.Container}>
      <div className={s.Tabs}>
        <Segmented
          options={modes}
          value={modes[data.mode]}
          onChange={(tv) => {
            const m = modes.indexOf(tv.toString());
            setData({ ...data, mode: m })
            saveMode(m);
          }}
        />
      </div>
      <div className={s.InputGroup}>
        <input
          className={s.Input} placeholder={getHelp(data.mode)}
          value={data.content}
          onChange={(e) => setData({ ...data, content: e.target.value })}
          disabled={data.submitting}
          maxLength={1000}
          onKeyUp={(e) => {
            if (e.key === 'Enter' && data.content.trim() !== "") {
              handleSendMessage();
            }
          }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24" height="24" viewBox="0 0 24 24" fill="none"
          onClick={handleSendMessage}
        >
          <path d="M16.14 2.96001L7.11 5.96001C1.04 7.99001 1.04 11.3 7.11 13.32L9.79 14.21L10.68 16.89C12.7 22.96 16.02 22.96 18.04 16.89L21.05 7.87001C22.39 3.82001 20.19 1.61001 16.14 2.96001ZM16.46 8.34001L12.66 12.16C12.51 12.31 12.32 12.38 12.13 12.38C11.94 12.38 11.75 12.31 11.6 12.16C11.4605 12.0189 11.3823 11.8284 11.3823 11.63C11.3823 11.4316 11.4605 11.2412 11.6 11.1L15.4 7.28001C15.69 6.99001 16.17 6.99001 16.46 7.28001C16.75 7.57001 16.75 8.05001 16.46 8.34001Z" fill="#615EF0" />
        </svg>
      </div>
    </div>
  )
};

export default ChatInput;
