
import React, { FC } from 'react';
import Component from './History';
import * as types from './types';
import { useNavigate } from 'react-router-dom';
import { getThreads, newThread } from 'services/ThreadService';

interface Props {
    wallet: any;
    layout: any;
    onPay: () => void;
}

const History:FC<Props> = ({ layout, wallet, onPay }) => {
    const [{ threads, mode }, setData] = React.useState<{ threads: types.Thread[], mode: string }>({ threads: [], mode: ">" });
    const navigate = useNavigate();

    React.useEffect(() => {
        async function fetThreads() {
            let oldThreads = await getThreads();
            if (oldThreads.length === 0) {
                oldThreads = await newThread();
                setTimeout(() => {
                    navigate(`/chat/${oldThreads[0].id}`);
                }, 500);
            }
            const m = window.localStorage.getItem(`t-m`) || "<";
            setData({ threads: oldThreads, mode: m });
        }
        fetThreads();
    }, []);

    return (
        <Component
            wallet={wallet}
            layout={layout}
            mode={mode}
            onPay={onPay}
            threads={mode === ">" ? threads : threads.slice().reverse()}
            onSort={() => {
                const m = mode === ">" ? "<" : ">";
                setData({ threads, mode: m });
                window.localStorage.setItem(`t-m`, m);
            }}
            onAddThread={() => {
                (async () => {
                    const threads = await newThread();
                    setTimeout(() => {
                        navigate(`/chat/${threads[threads.length - 1].id}`);
                    }, 500);
                    setData({ threads, mode });
                    return threads;
                })();
            }}
        />
    );
}

export default History;
