import {
  BrowserRouter,
  Routes as ReactRoutes,
  Route,
  useNavigate
} from 'react-router-dom';

import { Result } from 'antd';

import Background from './Background';
// import Home from './Home';
import HomeAI from './HomeAI';
// import Learn from 'pages/Learn';
// import Account from 'pages/Account';
import Policy from './Policy';
import Contact from './Contact';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Chat from 'components/Chat';
import React from 'react';
import { GetProfile } from 'services/SentenceService';

const Page404 = () => <Result
  status="404"
  title="Trang không tồn tại"
  subTitle="Bạn có chắc rằng mình dùng đúng đường dẫn chứ?"
// extra={<Button type="primary">Back Home</Button>}
/>

const Page403 = () => <Result
  status="403"
  title="Phát hiện gian lận"
  subTitle="Nếu bạn đang cố gắng lấy dữ liệu của English Major4, hãy dừng lại ngay. Chúng tôi sẽ khoá tài khoản nếu bạn vẫn tiếp tục."
// extra={<Button type="primary">Back Home</Button>}
/>

const CH = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    async function fetchProfile() {
      const p = await GetProfile();
      if (p) {
        let url = localStorage.getItem('nx');
        if (url) {
          localStorage.removeItem('nx');
          navigate(url, { replace: true });
          return;
        }
        navigate('/chat/-', { replace: true });
      }
    }
    fetchProfile();
  }, []);

  return null;
}

function App() {

  return (
    <>
      <BrowserRouter>
        <Background />
        <ReactRoutes>
          {/* <Route path="/:setId/:uid/:questionId/:mode" element={<Learn />} /> */}
          {/* <Route path='/tai-khoan/:tab' element={<Account />} /> */}
          <Route path='/dieu-khoan' element={<Policy />} />
          <Route path='/chat/:thread' element={<Chat />} />
          <Route path='/dang-ky' element={<Contact />} />
          <Route path="/thong-bao-403" element={<Page403 />} />
          <Route path='/ch' element={<CH />} />
          <Route path='' element={<HomeAI />} />
          <Route path="*" element={<Page404 />} />
        </ReactRoutes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
