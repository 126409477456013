
import React, { FC } from 'react';

import s from './SpeakModal.module.scss'
import { createReconizer } from 'services/Speech';
import {
    HiOutlineMicrophone as Mic,
    HiOutlineSpeakerWave as Sound,
    HiOutlineEye as Show,
    HiOutlineEyeSlash as Hide,
} from 'react-icons/hi2';
import WavingButton from 'components/WavingButton';
import { Modal } from 'antd';

interface Props {
    paragraph: string;
    onClose: Function;
    onSpeak: (content: string, onEnd: Function) => void;
}

const sameWord = (a: string, b: string) => {
    if (a === b) {
        return true;
    }

    const na = (a || '').replaceAll(/[^a-zA-Z\d\s:]/g, '');
    const nb = (b || '').replaceAll(/[^a-zA-Z\d\s:]/g, '');
    if (na === nb) {
        return true;
    }

    if (/.+or$/.test(na) && na === nb.replaceAll("our", "or")) {
        return true;
    }

    if (/.+or$/.test(nb) && nb === na.replaceAll("our", "or")) {
        return true;
    }

    let numbers: any = {
        "0": "zero",
        "1": "one",
        "2": "two",
        "3": "three",
        "4": "four",
        "5": "five",
        "6": "six",
        "7": "seven",
        "8": "eight",
        "9": "night",
        "10": "ten",
    };

    if (numbers[na] && numbers[na] === nb) {
        return true;
    }

    if (numbers[nb] && numbers[nb] === na) {
        return true;
    }

    // time
    numbers = {
        "0": "000",
        "1": "100",
        "2": "200",
        "3": "300",
        "4": "400",
        "5": "500",
        "6": "600",
        "7": "700",
        "8": "800",
        "9": "900",
        "10": "1000",
    };

    if (numbers[na] && (numbers[na] === nb || "0" + numbers[na] === nb)) {
        return true;
    }

    if (numbers[nb] && (numbers[nb] === na || "0" + numbers[nb] === na)) {
        return true;
    }

    return false
}

function normB(b: string, a: string) {
    if (a.toLocaleLowerCase().indexOf(" mph") >= 0) {
        return b
            .replaceAll("miles per hour", "mph")
            .replaceAll("mile per hour", "mph")
            .replaceAll("mile/hour", "mph")
            .replaceAll("miles/hour", "mph");
    }

    return b;
}

const SpeakModal: FC<Props> = ({
    paragraph,
    onClose,
    onSpeak
}) => {
    const a = paragraph;
    const [{ tries, bWords, bnWords, percent, b }, learnS] = React.useState<
        { bWords: string[], bnWords: string[], percent: number, b: string, tries: number }
    >({ bWords: [], bnWords: [], percent: 0, b: "", tries: 0 });
    const aRWords = a.trim().split(/\s+/);
    const aWords = a.trim().toLowerCase().split(/\s+/);
    const [rg, setRg] = React.useState<any>(null);
    const [playing, play] = React.useState<any>(false);
    const [showing, show] = React.useState<any>(true);

    function handleSpeak() {
        const rg = createReconizer(a, true);
        setRg(rg)
        rg.onresult = function (event: any) {
            let rs = "";
            for (let i = 0; i < event.results.length; ++i) {
                rs += event.results[i][0].transcript;
            }
            setB(rs);
            if (rs.split(/\s+/).length >= aWords.length) {
                rg.stop();
            }
        }

        rg.onend = function (event: any) {
            rg.stop();
            setRg(null);
        }

        rg.onerror = function (event: any) {
            rg.stop();
            setRg(null);
        }
        rg.start();
    }

    function setB(ib: string) {
        const rb = normB(ib, a);
        const nb = rb.replaceAll("'", "’");
        const words = nb.trim().split(/\s+/);
        const nWords = nb.trim().toLowerCase().split(/\s+/);

        let eCount = 0;
        aWords.forEach((w, i) => {
            if (!sameWord(w, nWords[i])) {
                ++eCount;
            }
        });

        const total = Math.max(aWords.length, nWords.length);
        let pc = words.length > 0 ? ((total - eCount) / total) * 100 : 0;
        pc = pc | 0;


        if (pc > 85 && Math.random() * 100 > 25) {
            setB(paragraph);
            return;
        }

        learnS({
            bWords: words,
            bnWords: nWords,
            percent: pc,
            b: rb,
            tries: tries + 1,
        });
    }

    return (
        <Modal
            title="Luyện nói"
            open
            onOk={() => { }}
            onCancel={() => onClose()}
            footer={[]}
        >
            <div className={s.Container}>
                {showing && <div className={s.Paragraph}>
                    {paragraph}
                </div>}
                <div className={s.Result}>
                    {b ? (<div className={s.Actionbox}>
                        <div className={s.Sentencecheck}>
                            {bnWords.length >= aWords.length && bnWords.map((w, i) => {
                                if (sameWord(w, aWords[i])) {
                                    return aRWords[i] + ' ';
                                }
                                return (<span key={i + w} className={s.Error}>{bWords[i]} </span>);
                            })}
                            {bnWords.length < aWords.length && aWords.map((w, i) => {
                                if (sameWord(w, bnWords[i])) {
                                    return aRWords[i] + ' ';
                                }
                                return (<span key={i + w} className={s.Error}>{bWords[i] || w.split('').map(() => "_")} </span>);
                            })}
                        </div>
                        <div className={s.Status}>
                            Kết quả chính xác <strong>{percent}%</strong>. Đã đọc <strong>{tries}</strong> lần.
                        </div>
                    </div>) : (
                        <div className={s.Actionbox}>
                            <div className={s.Sentencecheck}>
                                {aWords.map((w, i) => {
                                    if (w === bnWords[i]) {
                                        return bWords[i] + ' ';
                                    }
                                    return <span key={i + w}>{w.split('').map(() => "_")} </span>;
                                })}
                            </div>
                            <div className={s.Status}>
                                Kết quả chính xác <strong>{percent}%</strong>. Bạn đã làm <strong>{tries}</strong> lần.
                            </div>
                        </div>
                    )}
                </div>
                <div className={s.Speaker}>
                    <div className={s.Control} onClick={() => {
                        if (playing) {
                            play(false);
                            return;
                        }
                        play(true);
                        const size = paragraph.split('\s').length;
                        let to: any = setTimeout(() => {
                            if (to !== -1) {
                                clearTimeout(to);
                                to = -1;
                                play(false);
                            }
                        }, size * 950);
                        onSpeak(paragraph, () => {
                            play(false);
                            if (to != -1) {
                                clearTimeout(to);
                                to = -1;
                            }
                        });
                    }}>
                        <WavingButton waving={playing}>
                            <div className={s.Mic}>
                                <Sound />
                            </div>
                        </WavingButton>
                    </div>
                    <div
                        className={s.Control}
                        onClick={() => {
                            if (showing) {
                                show(false);
                                return;
                            }
                            show(true);
                        }}
                    >
                        <WavingButton waving={false}>
                            <div className={s.Mic}>
                                {showing ? <Show /> : <Hide />}
                            </div>
                        </WavingButton>
                    </div>
                    <div className={s.Control} onClick={() => {
                        if (rg) {
                            rg.stop();
                            return;
                        }
                        handleSpeak();
                    }}>
                        <WavingButton waving={!!rg}>
                            <div className={s.Mic}>
                                <Mic />
                            </div>
                        </WavingButton>
                    </div>
                    <div className={s.Help}>Chạm vào biểu tượng để bắt đầu</div>
                </div>
            </div>
        </Modal>
    )
};

export default SpeakModal;
