import { Avatar, Button, Dropdown } from 'antd';
import { UserOutlined, MenuOutlined, LogoutOutlined, FileProtectOutlined, CrownOutlined, FileSearchOutlined, HomeOutlined, KeyOutlined, LockOutlined } from '@ant-design/icons';

import s from './Navbar.module.scss';

import type { MenuProps } from 'antd';
import { GetProfile, LogIn, LogOut } from 'services/SentenceService';
import React from 'react';
import { MdSupportAgent } from 'react-icons/md';

const items: MenuProps['items'] = [
    {
        key: '0',
        label: (
            <a target="_self" rel="noopener noreferrer" href="/">
                <HomeOutlined style={{ marginRight: "12px" }} />
                Trang chủ
            </a>
        ),
    },
    {
        key: '1',
        label: (
            <a target="_self" rel="noopener noreferrer" href="/chat/-">
                <UserOutlined style={{ marginRight: "12px" }} />
                Học tiếng Anh
            </a>
        ),
    },
    // {
    //     key: '2',
    //     label: (
    //         <a target="_self" rel="noopener noreferrer" href="/tai-khoan/2">
    //             <CrownOutlined style={{ marginRight: "12px" }} />
    //             Mã kích hoạt
    //         </a>
    //     ),
    // },
    {
        key: '3',
        label: (
            <a target="_self" rel="noopener noreferrer" href="/dieu-khoan">
                <FileProtectOutlined style={{ marginRight: "12px" }} />
                Điều khoản sử dụng
            </a>
        ),
    },
    {
        key: '4',
        label: (
            <a target="_self" rel="noopener noreferrer" href="https://www.facebook.com/English.Major4">
                <MdSupportAgent style={{ marginRight: "12px" }} />
                Hỗ trợ
            </a>
        ),
    },
    {
        key: '5',
        label: (
            <a target="_self" rel="noopener noreferrer" onClick={(e) => {
                e.preventDefault();
                (async function () {
                    await LogOut();
                    window.location.href = "/";
                })();
            }}>
                <LogoutOutlined style={{ marginRight: "8px" }} /> Đăng xuất
            </a>
        ),
    },
    {
        key: '6',
        label: (
            <a target="_self" rel="noopener noreferrer" onClick={(e) => {
                e.preventDefault();
                (async function () {
                    await LogIn();
                })();
            }}>
                <LockOutlined style={{ marginRight: "8px" }} /> Đăng nhập
            </a>
        ),
    },
];

const signedKeys = ['1', '5'];
const unsingedKeys = ['6'];

export default function Navbar({ title }: { title: string }) {
    const [photo, setPhoto] = React.useState<any>(null);
    React.useEffect(() => {
        GetProfile(true).then((profile) => profile && setPhoto(profile.picture || null));
        window.document.title = title;
        setTimeout(() => {
            const nav = document.getElementById("nav-bar-chat");
            nav?.scrollIntoView({ behavior:"instant" } as any);
        }, 500);
    }, []);

    const fks = photo ? unsingedKeys : signedKeys;
    const its = items?.filter(i => !fks.includes(i?.key as string));

    return <div className={s.NavBar} id="nav-bar-chat">
        <div className={s.Left}>
            <Dropdown
                menu={{ items: its }}
                placement="bottomRight"
            >
                <Button icon={<MenuOutlined />} />
            </Dropdown>
            <h4>
                {title}
            </h4>
        </div>
        <div className={s.Middle}></div>
        <div className={s.Right}>
            {photo ? (<Dropdown menu={{ items: its }} placement="bottomRight">
                <Avatar
                    style={{ backgroundColor: '#87d068', cursor: 'pointer' }}
                    icon={<UserOutlined />}
                    src={photo}
                />
            </Dropdown>) : (<Button 
                onClick={() => {
                    LogIn();
                }}
                icon={<LockOutlined />}
                type="link">Đăng nhập</Button>
            )}
        </div>
    </div>
}
