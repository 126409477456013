
import React, { FC } from 'react';

import s from './ChatBox.module.scss';

import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import { GetProfile } from 'services/SentenceService';
import * as types from './types';
import AI from 'components/Logo/AI';
import { useNavigate, useParams } from 'react-router-dom';
import { getVoices, speak } from 'services/Speech';
import { Button, Dropdown, Popover, Space, notification } from 'antd';
import { DeleteOutlined, DownOutlined, DownloadOutlined } from '@ant-design/icons';
import SpeakModal from './SpeakModal/SpeakModal';
import NotePad from './NotePad';
import { wsHook, wsSend } from './SocketService';
import { exportThread, getMessages, removeThread, saveMessage } from 'services/ThreadService';

interface Props {
    layout: any;
    onPay: () => void;
    onCharged: () => void;
}

function getVoiceItems(voices: { name: string, lang: string }[], onSelect: Function) {
    return voices.map((v, i) => ({
        key: i + v.name,
        label: (
            <div onClick={() => onSelect(v, i)}>
                {v.name}
            </div>
        ),
    }));
}

const ChatBox: FC<Props> = ({ layout, onPay, onCharged }) => {
    const [api, contextHolder] = notification.useNotification();
    const [messages, setMessages] = React.useState<types.Message[]>([]);
    const { thread: threadId } = useParams();
    const [speaking, setSpeaking] = React.useState(false);
    const [voiceIdx, setVoiceIdx] = React.useState(0);
    const [practice, setPractice] = React.useState<any>(null);
    const [profile, setProfile] = React.useState<any>(null);
    const [deleting, setDeleting] = React.useState(false);

    const [voices, setVoices] = React.useState<{ name: string, lang: string }[]>([]);
    React.useEffect(() => {
        getVoices(setVoices);
    }, []);

    React.useEffect(() => {
        if (!threadId || threadId === "-") {
            return;
        }

        (async function () {
            const profile = await GetProfile();
            const msgs = await getMessages(threadId);
            setMessages(msgs);
            setProfile(profile);
            scrollToBottom();
        })();

        const showBalanceError = () => {
            const key = `open${Date.now()}`;
            const btn = (
                <Space>
                    <Button type="link" size="small" onClick={() => api.destroy()}>
                        Huỷ
                    </Button>
                    <Button type="primary" size="small" onClick={() => {
                        api.destroy(key);
                        onPay();
                    }}>
                        Tiếp tục
                    </Button>
                </Space>
            );
            api.warning({
                duration: 0,
                message: 'Không đủ M4',
                description:
                    'Số lượng M4 còn lại không đủ để thực hiện thao tác này. Bạn cần có thêm M4 để tiếp tục sử dụng.',
                btn,
                key,
                onClose: () => { },
            });
        }

        const onMessage = async (rp: any, err: any) => {
            if (err) {
                const { thread: mid } = rp;
                const pm = {
                    id: mid,
                    status: -1,
                    lastAction: rp,
                }

                const nmsgs = await saveMessage(threadId, pm, false);
                if (!nmsgs) {
                    api.error({
                        message: 'Có lỗi xảy ra, vui lòng thử lại trong giây lát',
                    });
                    return;
                }

                setMessages(nmsgs);
                return;
            }

            let { action, thread, type, data, usage } = rp;

            if (usage) {
                setTimeout(onCharged, 1000);
            }

            if (/^["].+["]$/g.test(data)) {
                data = data.substring(1, data.length - 1);
            }

            let nmsgs: types.Message[] = [];
            if (action === 100 || action === 102 || action === 103) {
                const m: any = {
                    id: thread,
                    status: type,
                    updatedAt: new Date().getTime()
                }
                if (usage) {
                    m.usage = usage;
                }

                if (type === -1) {
                    m.status = 1;
                    setTimeout(showBalanceError, 500);
                } else if (type === 0) {
                    if (action === 103) {
                        m.vietnamese = data;
                    } else {
                        m.content = data;
                    }
                }

                const ms = await saveMessage(threadId, m, false);
                if (ms !== null) {
                    nmsgs = ms;
                }
            } else if (action === 101) {
                const nextId = `${thread}.rp`;
                let ms = await saveMessage(threadId, { id: thread, status: type !== -1 ? type : 1, repliedId: nextId }, false);
                if (ms !== null) {
                    nmsgs = ms;
                }
                if (type === -1) {
                    showBalanceError();
                } else if (type !== 1) {
                    ms = await saveMessage(threadId, {
                        id: nextId,
                        content: data,
                        action: 101,
                        sender: {
                            id: "-",
                            name: "",
                            role: 2,
                        },
                        createdAt: new Date().getTime(),
                        updatedAt: new Date().getTime(),
                        status: type
                    }, true);
                    if (ms !== null) {
                        nmsgs = ms;
                    }
                } else {
                    const ms = await saveMessage(threadId, { id: nextId, usage, status: type }, false);
                    if (ms !== null) {
                        nmsgs = ms;
                    }
                }
            }
            if (nmsgs.length > 0) {
                setMessages(nmsgs);
                scrollToBottom();
            }
        };

        return wsHook(onMessage);
    }, [threadId]);

    const scrollToBottom = () => {
        setTimeout(() => {
            document.getElementById("msg-ctn")?.scrollIntoView({ behavior: 'smooth' } as any);
        }, 200);
    }

    const handleSpeak = (content: string, onEnd: Function) => {
        if (speaking) {
            return;
        }
        setSpeaking(true);
        speak(content, voices[voiceIdx], () => {
            setSpeaking(false)
            onEnd();
        });
    };

    const handleSendMessage = (msg: types.Message, action?: number) => {
        (async function () {
            const itAction = action || msg.action;
            if (msg.id === "-") {
                msg.id = `${itAction}.${messages.length + 1}.${Math.random() * 100}`;
                if (itAction === 102) {
                    msg.vietnamese = msg.content;
                }
                const msgs = await saveMessage(threadId as string, { ...msg, status: undefined }, true);
                if (msgs !== null) {
                    setMessages(msgs);
                }
            } else {
                const msgs = await saveMessage(threadId as string, { ...msg, repliedId: '-', status: undefined }, false);
                if (msgs !== null) {
                    setMessages(msgs);
                }
            }

            const { id, content } = msg;
            wsSend({
                action: itAction,
                thread: id,
                type: 0,
                data: content
            })

            if (itAction !== 103) {
                scrollToBottom();
            }
        })()
    }

    const handleResendMessage = (la: any) => {
        (async function () {
            const { thread, action, type, data } = la;
            const msgs = await saveMessage(threadId as string, { id: thread, status: undefined, updatedAt: Date.now() }, false);
            if (msgs === null) {
                return;
            }
            setMessages(msgs);
            wsSend({
                action,
                thread,
                type,
                data
            })
        })();
    }

    if (!profile) {
        return null;
    }

    const DeleteConfirm = (
        <div>
            <div>Bạn muốn xoá bài học này?</div>
            <div style={{ textAlign: 'right', width: "100%", marginTop: "4px" }}>
                <Button
                    size="small"
                    type="primary"
                    onClick={async () => {
                        setDeleting(false);
                        await removeThread(threadId as string);
                        setTimeout(() => {
                            window.location.href = "/chat/-";
                        }, 500);
                    }}
                    >
                    Xoá
                </Button>
            </div>
        </div>
    );

    return (<>
        {contextHolder}
        <div className={s.Box} style={layout.isSmall ? { width: (layout.main || layout.right) ? `${window.innerWidth - 32}px` : 'auto' } : {}}>
            {layout.main ? <div className={s.Container} style={layout.right ? { flex: 0.6 } : { flex: 1 }}>
                <div className={s.Header}>
                    <div className={s.Profile}>
                        <AI className={s.Avatar} />
                        <div className={s.Meta}>
                            <div className={s.Name}>Matt</div>
                            <div className={s.Status}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
                                    <circle cx="5" cy="5.5" r="5" fill="#68D391" />
                                </svg>
                                Online
                            </div>
                        </div>
                    </div>
                    <div className={s.Tools}>
                        <Button
                            style={{ marginRight: "8px" }}
                            disabled={messages.length <= 1}
                            title="Tải xuống"
                            onClick={() => exportThread(threadId as string)}
                        ><DownloadOutlined /></Button>
                        <Popover 
                            placement="bottomLeft" 
                            open={deleting}
                            trigger="click"
                            content={DeleteConfirm}
                            onOpenChange={(open) => setDeleting(open)}
                        >
                            <Button
                            ><DeleteOutlined /></Button>
                        </Popover>
                    </div>
                </div>
                <div className={s.Body} style={window.innerHeight < 800 ? { height: `${window.innerHeight - 122}px` } : {}}>
                    {profile && messages.map((message, idx) => (
                        <ChatMessage
                            key={threadId + message.id + idx}
                            message={message}
                            profile={profile}
                            speaking={speaking}
                            onSpeak={handleSpeak}
                            onPractice={(paragraph: string) => setPractice({ paragraph })}
                            onSendMessage={handleSendMessage}
                            onResendMessage={handleResendMessage}
                            showAvatar
                        />
                    ))}
                    <div id="msg-ctn" style={{ width: "100%", height: "10px" }} />
                </div>
                <div className={s.Bottom}>
                    <ChatInput
                        onSendMessage={handleSendMessage}
                    />
                    {window.innerWidth >= 520 && voices.length > 0 && (<div className={s.Voices}>
                        <div className={s.Label}>Giọng đọc </div>
                        <Dropdown
                            menu={{
                                items: getVoiceItems(voices, (_: any, vIdx: number) => setVoiceIdx(vIdx))
                            }}
                            placement="top"
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    <div>{voices[voiceIdx].name}</div>
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown>
                    </div>)}
                </div>
            </div> : null}
            <NotePad
                layout={layout}
                onSendMessage={handleSendMessage}
            />
        </div>
        {practice && <SpeakModal
            onSpeak={handleSpeak}
            paragraph={practice.paragraph}
            onClose={() => {
                setPractice(null);
            }}
        />}
    </>);
}

export default ChatBox;
