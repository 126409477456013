import React, { FC } from 'react';

import * as types from './types';
import s from './ChatMessage.module.scss'
import { Button } from 'antd';
import { HiMiniMicrophone, HiMiniSpeakerWave } from "react-icons/hi2";
import { MdOutlineChatBubbleOutline, MdOutlineGTranslate } from "react-icons/md";

import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import WavingButton from 'components/WavingButton';
import AI from 'components/Logo/AI';

interface Props {
  message: types.Message;
  showAvatar: boolean;
  profile: any;
  onSpeak: (content: string, onEnd: Function) => void;
  speaking: boolean;
  onPractice: Function;
  onSendMessage: Function;
  onResendMessage: Function;
}

function padNum(num: number) {
  return num < 10 ? '0' + num : num;
}

function timeFmt(date: number) {
  const d = new Date(date || 0);
  const now = new Date();

  const time = `${padNum(d.getHours())}:${padNum(d.getMinutes())}`;

  if (d.getTime() > now.getTime() - 24 * 60 * 60000) {
    return time
  }

  if (d.getFullYear() !== now.getFullYear()) {
    return `${padNum(d.getDate())}/${padNum(d.getMonth() + 1)}/${d.getFullYear()} ${time}`;
  }

  return `${d.getDate()}/${d.getMonth() + 1} ${time}`;
}

const ChatMessage: FC<Props> = ({ message, speaking: ctxSpeaking, onSpeak, onSendMessage, onResendMessage, onPractice, showAvatar, profile }) => {
  const isMe = message.sender.role === 1;
  const [speaking, setSpeaking] = React.useState(false);

  function handleSendMessage() {
    onSendMessage(message, 101);
  }

  function handleTranslate() {
    onSendMessage(message, 103);
  }

  const isVerySmall = window.innerWidth < 520;
  if (message.lastAction) {
    console.log("error with last action", message);
  }

  return (
    <div className={s.Container}>
      <div className={s.Wrapper}>
        {!isVerySmall && <AI
          className={(isMe || !showAvatar) ? [s.Avatar, s.Hidden].join(' ') : s.Avatar}
        />}
        <div className={isMe ? [s.Message, s.Mine].join(' ') : s.Message}>
          <div style={{ width: isVerySmall ? `${window.innerWidth - 110}px` : 'auto' }}>
            <div className={s.Text} style={isVerySmall ? { minWidth: "auto" } : {}}>
              {message.content.split('\n').map((text, index) => (
                <div key={index + text}>
                  <div
                    className={text === "" ? s.Line + ' ' + s.Empty : s.Line}
                  >
                    {text}
                  </div>
                </div>
              ))}
              {message.vietnamese && message.vietnamese != message.content && (<>
                <hr />
                {message.vietnamese.split('\n').map((text, index) => (
                  <div key={index + text}>
                    <div
                      className={text === "" ? s.VLine + ' ' + s.Empty : s.VLine}
                    >
                      {text}
                    </div>
                  </div>
                ))}
              </>)}
              <div className={s.Sub}>
                {message.status === undefined && (Date.now() - message.updatedAt < 10000) && <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 12, marginRight: "4px" }}
                      spin
                    />}
                />}
                {timeFmt(message.updatedAt)}{message.usage && (', ' + message.usage.total_tokens + 'm4')}
              </div>
            </div>
            {!message.isSystem && message.status == -1 && message.lastAction && <div className={s.Tools}>
              <div className={s.Error}>
                <ExclamationCircleOutlined /> <span>Có lỗi xảy ra! <a
                  onClick={(e) => {
                    e.preventDefault();
                    onResendMessage(message.lastAction);
                  }}
                >Thử lại</a>
                </span>
              </div>
            </div>}
            {!message.isSystem && message.status != -1 && <div className={s.Tools}>
              <Button
                className={s.Button} size="small"
                type="text"
                onClick={() => {
                  onPractice(message.content);
                }}
                icon={
                  <HiMiniMicrophone
                    style={{ marginTop: 1 }}
                    size={18}
                  />
                }></Button>
              <WavingButton waving={speaking}>
                <Button
                  className={s.Button}
                  disabled={ctxSpeaking}
                  onClick={() => {
                    if (ctxSpeaking) {
                      return;
                    }
                    setSpeaking(true);
                    const size = message.content.split('\s').length;
                    let to: any = setTimeout(() => {
                      if (to !== -1) {
                        clearTimeout(to);
                        to = -1;
                        setSpeaking(false);
                      }
                    }, size * 950);
                    onSpeak(message.content, () => {
                      setSpeaking(false);
                      if (to != -1) {
                        clearTimeout(to);
                        to = -1;
                      }
                    });
                  }}
                  size="small" type="text"
                  icon={
                    <HiMiniSpeakerWave
                      size={20}
                    />
                  }
                ></Button>
              </WavingButton>
              {message.status !== undefined && !message.vietnamese && <Button
                className={s.Button}
                size="small"
                type="text"
                onClick={handleTranslate}
                icon={
                  <MdOutlineGTranslate
                    size={18}
                    style={{ marginTop: 1 }}
                  />
                }></Button>}
              {message.action !== 101 && !message.repliedId && (<Button
                className={s.Button}
                size="small"
                type="text"
                onClick={() => {
                  handleSendMessage();
                }}
                icon={
                  <MdOutlineChatBubbleOutline
                    size={18}
                    style={{ marginTop: 1 }}
                  />
                }></Button>)}
            </div>}
          </div>
        </div>
        <div
          className={(!isMe || !showAvatar) ? [s.Avatar, s.Hidden].join(' ') : s.Avatar}
          style={isVerySmall ? { display: "none" } : { overflow: 'hidden' }}
        >
          {isMe && <img
            src={profile.picture}
            width="100%"
            height="100%"
            style={{ objectFit: 'cover', backgroundColor: 'white' }}
            alt=''
          />}
        </div>
      </div>
    </div>
  )
};

export default ChatMessage;
