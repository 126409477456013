
import { Button } from 'antd';
import s from './HomeAI.module.scss';

// import { LogIn } from 'services/SentenceService';
import { useNavigate } from 'react-router-dom';
import Navbar from 'components/Chat/Navbar';

export default function HomeAI() {
    const navigate = useNavigate();

    // const handleLogIn = () => {
    //     const signed = !!(window as any).m4jProfile;
    //     if (!signed) {
    //         LogIn();
    //         return;
    //     }
    //     navigate("/chat/-");
    // }

    return (<div className={s.Container}>
        <Navbar title='Major4 English' />
        <div className={s.Page}>
            <div className={s.Quote} />
            {/* <img className={s.Logo} src="/common/logo.png" /> */}
            <div className={s.Body}>
                <div className={s.Main}>
                    Major4 English cung cấp công cụ học tiếng Anh hiệu quả trên hầu hết các thiết bị — máy tính, tablet, và điện thoại — để bạn có thể dễ dàng tiếp cận với việc học tiếng Anh.
                    <br/>
                    <br/>
                    Chúng tôi cung cấp cho bạn nhiều phương pháp học tiếng Anh để bạn có thể chọn lựa. Trong đó một phương pháp mà chúng tôi tin là hỗ trợ bạn học một cách hiệu quả nhất chính là <span className={s.Highlight}>bạn nghĩ gì thì nói hoặc viết về nó</span>.
                    <span> Phương pháp này <span className={s.Highlight}>mở khoá giới hạn từ vựng của bạn</span> <span> và giúp bạn học tiếng Anh một cách tự nhiên nhất.</span></span>
                </div>
                <div className={s.Sub}>
                    <strong>Major4 English</strong>, cam kết nâng cấp tiếng Anh của bạn lên một tầm cao mới.
                    <br/>
                    Hãy đăng ký để trải nghiệm ngay hôm nay!
                </div>
            </div>
            <div className={s.Footer}>
                <div className={s.Left}>
                    <Button 
                        type="primary" 
                        onClick={() => {
                            window.location.href = 'https://www.facebook.com/English.Major4';
                        }} 
                        size="large"
                    >Liên hệ hỗ trợ</Button>
                    <Button onClick={() => {
                        navigate('/dang-ky');
                    }} type="link" size="large">Đăng ký</Button>
                </div>
                <div className={s.Fill}></div>
                {/* <div>
                <Button onClick={LogIn} type="link">Đăng ký học</Button>
            </div> */}
            </div>
        </div>
    </div>);
}
