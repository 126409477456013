import jsp from 'jsonpack';
import papa from 'papaparse';

import { GetProfile } from "./SentenceService";

export interface Thread {
    id: string;
    title: string;
    ws: number;
    m4: number;
    createdAt: number;
    updatedAt: number;
    firstThread?: boolean;
}

export interface Message {
    id: string;
    sender: {
        id: string;
        name: string;
        role: number;
    }
    usage?: {
        prompt_tokens: number;
        completion_tokens: number;
        total_tokens: number;
    }
    vietnamese?: string;
    action: number,
    content: string;
    createdAt: number;
    updatedAt: number;
    status?: number;
    error?: string;
    repliedId?: string;
    isSystem?: boolean;
    lastAction?: any;
}

export async function getTKey() {
    const profile = await GetProfile();
    const tKey = btoa(`${profile?.email || ''}-pts`);
    return tKey;
}

export async function getMKey(threadId: string) {
    const profile = await GetProfile();
    const mKey = btoa(`${profile?.email || ''}-${threadId}-pms`);
    return mKey;
}

export async function getThreads() {
    const tKey = await getTKey();
    const data = window.localStorage.getItem(tKey);
    let threads: Thread[] = [];
    if (data) {
        threads = jsp.unpack(data);
    }
    return threads;
}

async function saveThreads(threads: Thread[]) {
    const tKey = await getTKey();
    const pk = jsp.pack(threads);
    window.localStorage.setItem(tKey, pk);
}

export async function newThread() {
    const threads = await getThreads();
    const d = new Date();
    const month = d.getMonth() + 1;
    const day = d.getDay();
    const date = d.getDate();
    const year = d.getFullYear();
    const days = ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'];

    threads.push({
        id: `${month}-${date}-${year}-${threads.length + 1}`,
        title: `[${threads.length + 1}] ${days[day]}, ${date}/${month}/${year}`,
        ws: 0,
        m4: 0,
        createdAt: d.getTime(),
        updatedAt: d.getTime(),
        firstThread: threads.length === 0,
    });

    await saveThreads(threads);
    return threads;
}

async function saveMessages(threadId: string, messages: Message[]) {
    const mKey = await getMKey(threadId);
    const pk = jsp.pack(messages);
    window.localStorage.setItem(mKey, pk);
}

export async function saveMessage(threadId: string, updates: any, adding: boolean) {
    let messages = await getMessages(threadId);
    let found = false;

    messages = messages.map((msg) => {
        if (msg.id === updates.id) {
            found = true;

            if (msg.usage && updates.usage) {
                updates.usage = {
                    total_tokens: msg.usage.total_tokens + updates.usage.total_tokens,
                    completion_tokens: msg.usage.completion_tokens + updates.usage.completion_tokens,
                    prompt_tokens: msg.usage.prompt_tokens + updates.usage.prompt_tokens,
                }
            }

            return {
                ...msg, 
                ...updates,
                updatedAt: Date.now(),
            };
        }
        return msg;
    });

    if (!found) {
        if (!adding) {
            return null;
        }

        messages.push(updates as Message);
    }

    await saveMessages(threadId, messages);
    return messages;
}

function greeting(name: string) {
    const today = new Date()
    const curHr = today.getHours()
    if (curHr < 12) {
        return `Good morning, ${name}.`;
    }

    if (curHr < 18) {
        return `Good afternoon, ${name}.`;
    }

    return `Good evening, ${name}.`;
}

export async function getMessages(threadId: string) {
    const mKey = await getMKey(threadId);
    const data = window.localStorage.getItem(mKey);
    let tMsgs: Message[] = [];
    if (data) {
        tMsgs = jsp.unpack(data);
    }
    if (tMsgs.length === 0) {
        const profile = await GetProfile();
        const threads = await getThreads();
        const cur = threads.find((t) => t.id === threadId);
        const content = 
            (cur && !cur.firstThread)
            ? `${greeting(profile.given_name)} What do you want to learn today?`
            : `Xin chào, ${profile.given_name}! Bạn hãy chọn một trong các cách sau để học nhé:\n\n1. Nhập nguyên văn bằng tiếng Anh. Mình sẽ bám sát nội dung để trò chuyện với bạn.\n\n2. Nhập ý chính bằng tiếng Anh. Mình sẽ giúp bạn chuyển nó thành nội dung hoàn chỉnh.\n\n3. Nhập nguyên văn bằng tiếng Việt. Bạn nghĩ gì thì viết đó, mình sẽ giúp bạn chuyển sang tiếng Anh.\n\n\n(***) Trong quá trình học, hãy thử các biểu tượng(icon) xuất hiện bên dưới tin nhắn theo thứ tự: "tập phát âm", "nghe", "dịch nghĩa" và "phản hồi tin nhắn" để rèn luyện khi cần nhé.\n\n\n-------------\nNào hãy bắt đầu bằng cách nhập nội dung vào ô bên dưới.\n\nChúc bạn học tập tốt!`;

            tMsgs = [{
            id: `default-${Math.random() * 100}`,
            content,
            action: 101,
            sender: {
                id: "-",
                name: "",
                role: 2,
            },
            createdAt: Date.now(),
            updatedAt: Date.now(),
            isSystem: true,
            status: 1
        }];
    }

    return tMsgs;
}

export async function removeThread(threadId: string) {
    const threads = await getThreads();
    const newThreads = threads.filter((t) => t.id !== threadId);
    const mKey = await getMKey(threadId);
    await saveThreads(newThreads);
    window.localStorage.removeItem(mKey);
    return newThreads;
}

export async function exportThread(threadId: string) {
    const messages = await getMessages(threadId);
    const data = messages.filter(m => !m.isSystem).map((msg) => ({
        "Nội dung": msg.content || '',
        "Tiếng Việt": msg.vietnamese || ''
    }));

    const csv = papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = 'Conversation.csv';
    link.style.position = 'fixed';
    link.style.top = '-100px';
    link.style.opacity = '0';
    link.style.width = '0';
    link.style.height = '0';
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
        document.body.removeChild(link);
    }, 1000);
}
