import { md5 } from 'js-md5';
// import { tryOpenInBrowser } from './Browser';

const HOST = process.env.REACT_APP_API_URL || 'https://english.major4.io';
let profile:any = undefined;
let words: any = {};

async function request(path: string, options?: { body?: any, method?: string, headers?: any, unstrict?: boolean }) {
    const t = new Date();
    const tk = (t.getTime()/1000) | 0;
    const dt = t.getDate() + tk;
    let p:any = null;

    if (path.indexOf('/auth/profiles') < 0) {
        p = await GetProfile();
    }

    const rand = (Math.random() * 1000 | 0) + '';
    let key = dt + '' + (p?.picture || '') + rand;
    key = md5(key);

    let nkey = 'v1/' + path + tk + rand;
    nkey = md5(nkey);

    const ck = 'a' + nkey.substring(1, 8);

    const { headers, unstrict, ...restOps } = options || {};
    const url = path.indexOf(HOST) === 0 ? path : `${HOST}/v1/${path}`;
    const rp = await fetch(url, {
        ...restOps,
        mode: 'cors',
        credentials: 'include',
        headers: {
            ...headers,
            'Content-Type': 'application/json',
            'X-Trace-Id': `${ck}.${tk}.${key}.${rand}`,
        }
    })

    if (!unstrict && rp.status === 401) {
        profile = undefined;
        window.location.href = HOST + '/auth/google/si';
    }
    return rp;
}

async function GET(path: string, options?: { body?: any, headers?: any, unstrict?: boolean }) {
    return request(path, {
        ...options,
        method: 'GET'
    });
}

async function DELETE(path: string, options?: { body?: any, headers?: any, unstrict?: boolean }) {
    return request(path, {
        ...options,
        method: 'DELETE'
    });
}

async function POST(path: string, options?: { body?: any, headers?: any, unstrict?: boolean }) {
    return request(path, {
        ...options,
        method: 'POST'
    });
}

export async function GetBooks() {
    const rq = await GET('books');
    return rq.json();
}

export async function LogIn() {

    let nx = window.location.pathname;
    if (nx === '/') {
        nx = '/chat/-';
    } else {
        nx += window.location.search;
    }

    localStorage.setItem('nx', nx);
    const url = HOST + '/auth/google/si';
    window.location.href = HOST + '/auth/google/si';
    // if (!tryOpenInBrowser(url)) {
    //     window.location.href = HOST + '/auth/google/si'; 
    // }
}

export async function LogOut() {
    const rq = await DELETE(HOST + '/auth/sessions');
    return rq.json();
}

export async function GetProfile(unstrict?: boolean) {
    if (profile) {
        return profile;
    }

    const rq = await GET(HOST + '/auth/profiles', { unstrict });
    const p = await rq.json();
    profile = p;

    if (!unstrict && !profile) {
        await LogOut();
    }

    return p;
}

export async function InitWs() {
    const rq = await POST(HOST + '/v1/ai/ws/init');
    return rq.json();
}

export async function Register(payload:any) {
    const rq = await POST(HOST + '/v1/user/register', { body: JSON.stringify(payload) });
    return rq.json();
}

export async function GetWallet() {
    const rq = await GET('wallet/meta');
    return rq.json();
}

export async function GetLearningBooks(bookId: string) {
    const rq = await GET('english/learn/books');
    return rq.json();
}

export async function GetWord(bookId: string, uid: number, mode: number) {
    const key = `${bookId}-${uid}-${mode}`;
    if (words[key]) {
        return words[key];
    }
    const rq = await GET(`english/learn/${bookId}/words/${uid}/${mode}`);
    const w = await rq.json();
    if (w) {
        words[key] = w;
    }
    return w;
}

export async function GetWords(bookId: string, mod: string, uid: number, pg: number, kw: string, size: number) {
    const rq = await GET(`english/learn/${bookId}/search/words/${uid}/${pg}/${kw || "*"}/${mod}/${size}`);
    return rq.json();
}

export async function LearnSentence(bookId: string, uid: number, percent: number) {
    const rq = await POST(`english/learn/${bookId}/sentences/${uid}/${percent}`);
    return rq.json();
}

export async function Buy(itemKey: string, quantity: number) {
    const rq = await POST("orders", { body: JSON.stringify({ key: itemKey, quantity })});
    return rq.json();
}

export async function PayConfirm(orderCode: number) {
    const rq = await POST(`orders/return/${orderCode}`);
    return rq.json();
}

export async function ActivateKey(key: string) {
    const rq = await POST(`keys/${key}`);
    return rq.json();
}

export async function GetActivatedKeys(params: { limit: number, offset: number }) {
    const rq = await POST(`keys/activated`, { body: JSON.stringify(params) });
    return rq.json();
}

export async function GetKeys(params: { limit: number, offset: number }) {
    const rq = await POST(`keys`, { body: JSON.stringify(params) });
    return rq.json();
}
